import { useEffect } from "react";
import {
  Outlet
} from "react-router-dom";

import { useAuth } from './auth'


export const Layout = function() {
  return (
  <div className="App">
    <Outlet />
  </div>)
}

export const UserLayout = function() {
  const {user, getUser, signin} = useAuth()
  useEffect(() => {
    const func = async () => {
      const returnUser = await getUser();
      if(!returnUser) {
        signin();
      }
    }
    func();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if(user)
    return (
      <Layout/>
    )
}