import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { createContext, useContext, useMemo, useState } from 'react';
import {
  useLocation
} from "react-router-dom";

const settings = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENTID,
  response_type: "code",
  scope: "openid email org com.read_orders",
  userStore: new WebStorageStateStore({ store: localStorage})
};

function useQuery() {
  const { search } = useLocation();
  const memo = useMemo(() => new URLSearchParams(search), [search])
  return memo;
}

const userManager = new UserManager(settings);

const Context = createContext({});

function UserContextProvider(props) {
  const query = useQuery();
  const orgid = query?.get('orgid');
  const locale = query?.get('locale');
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)

  const signin = () => {
    userManager.signinRedirect({
      redirect_uri: `${window.location.origin}/signin?returnUrl=${encodeURIComponent(window.location.href)}`, 
      extraQueryParams : { orgid: orgid, locale: locale }})
  }

  const signinCallback = async () => {
    setLoading(true)
    try {
      const userReturn = await userManager.signinCallback();
      if(userReturn)
        setUser(userReturn);
      return userReturn;
    }
    finally {
      setLoading(false);
    }
  }

  const getUser = async () => {
    setLoading(true)
    try {
      const userReturn = await userManager.getUser();
      if(userReturn) {
        if(userReturn.expired || (orgid && orgid !== userReturn.profile.orgid)) {
          userManager.removeUser();
          return;
        }
        setUser(userReturn);
      }
        
      return userReturn;
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Context.Provider value={{user : user, loading: loading, getUser: getUser, signin: signin, signinCallback: signinCallback}}>
      {props.children}
    </Context.Provider>
  )
}

const useAuth = () => {
  const auth = useContext(Context)
  return auth
}

export { UserContextProvider, useAuth }
