import { useEffect, useState } from 'react'
import {
  Navigate
} from 'react-router-dom';
import { useAuth } from '../auth'

function Page() {

  const {signinCallback} = useAuth()
  const [success, setSuccess] = useState()
  const [returnUrl, setReturnUrl] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    const func = async () => {
      try {
        await signinCallback()
        const query = new URLSearchParams(window.location.search);
        const returnUrl = query.get('returnUrl');
        setReturnUrl(returnUrl);
        setSuccess(true)
      }
      catch {
        setError(true)
      }
    }
    func();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(returnUrl)
      window.location.href = returnUrl;
  }, [returnUrl])

  if(success && !returnUrl)
    return <Navigate to='/'/>

  if(error)
    return <h1>Error</h1>

  return (
    <>
      <h1>Loading ...</h1>
    </>
  );
}
export default Page;
