import { useAuth } from '../auth'
import { useEffect } from 'react';

function Page() {
  const {user} = useAuth()

  useEffect(() => {
    (async function() {
      const json = await fetch(`https://apis.hara.vn/com/orders/${1043566161}.json`, {
        headers: {
          Authorization : `Bearer ${user.access_token}`
        }
      });
      console.log(await json.json());
    })();
  }, [user])

  return (
    <>
      <h1>{user.profile.email}</h1>
    </>
  );
}
export default Page;
