import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import SignIn from './pages/signin'
import Home from './pages/home'
import Print from './pages/print'
import {Layout, UserLayout} from './layout'
import { UserContextProvider } from './auth'

function App() {
  return (
    <BrowserRouter>
      <UserContextProvider>
          <Routes>
          <Route element={<UserLayout />}>
              <Route index element={<Home />} />
              <Route path="/print" element={<Print />} />
            </Route>

            <Route element={<Layout />}>
              <Route path="/signin" element={<SignIn />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </UserContextProvider>
    </BrowserRouter>
  );
}

const NotFound = function() {
  return (
    <h1>404</h1>)
}

export default App;
