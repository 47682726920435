import { useEffect, useState } from 'react';
import { useAuth } from '../auth'
import { jsPDF } from "jspdf";
import "jspdf-barcode";

function Page() {
  const { user } = useAuth()
  const [pdfDoc, setPdfDoc] = useState()

  useEffect(() => {
    (async function() {
      const json = await fetch(`https://apis.hara.vn/com/orders/${1043566161}.json`, {
        headers: {
          Authorization : `Bearer ${user.access_token}`
        }
      });
      console.log(await json.json());
    })();
  }, [user])


  useEffect(() => {
    const doc = new jsPDF();
    doc.text("Hello world!", 10, 10).barcode("barcodeValue", {
      fontSize: 23,
      textColor: "#000000",
      x: 5.4,
      y: 25.5
    });
    setPdfDoc(doc.output("datauristring"));
  }, [])

  if(!pdfDoc)
    return (<h1>Loading ...</h1>)

  return (
    <iframe title='Pdf Viewer' src={pdfDoc} width="100%" height="100%"/>
  );
}
export default Page;
